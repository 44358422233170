<template>
  <ApLoading v-if="isLoading" class="mt-10" text="Loading Primary Rule..." />

  <RulesStatusError v-else-if="isError" @click="getPrimaryRule" />

  <div v-else class="pa-4 mx-auto" style="max-width: 600px">
    <div class="text-h5 font-weight-black mb-2">
      What should the Primary records be?
    </div>

    <div class="text-body-2 mb-4">
      <v-icon small class="mr-1" color="primary">
        $mdi-information-outline
      </v-icon>
      Set the fields and their criteria that can determine which records will be
      considered the primary from each entity.
    </div>

    <v-tabs v-model="selectedTabIndex" background-color="ap-dark-blue-10">
      <v-tab
        v-for="entity in multiMapRule.entities"
        :key="entity"
        color="primary"
      >
        {{ entity }}
      </v-tab>

      <v-tabs-slider color="ap-dark-blue"></v-tabs-slider>

      <v-tab-item v-for="entity in multiMapRule.entities" :key="entity" eager>
        <v-card flat class="mt-1">
          <PrimaryRuleCriteriasList
            v-if="payload[entity]"
            :items="payload[entity]"
            :entity="multiMapRule.entities[selectedTabIndex]"
            @edit="onEdit"
            @delete="onDelete"
          />

          <div class="d-flex justify-end">
            <v-btn
              text
              block
              outlined
              small
              class="text-none font-weight-regular"
              @click="onAddNew"
            >
              <v-icon left>$mdi-plus</v-icon>
              {{
                payload[entity] && payload[entity].length > 0
                  ? 'New Criteria'
                  : 'Insert your first Criteria'
              }}
            </v-btn>
          </div>

          <PrimaryRuleFormCriteria
            v-if="isCriteriaDialogVisible"
            :conditions="selectedCriteria"
            :entity="multiMapRule.entities[selectedTabIndex]"
            @save="onSave"
            @cancel="onCancel"
          />
        </v-card>
      </v-tab-item>
    </v-tabs>
  </div>
</template>

<script>
import ApLoading from '@/components/common/ApLoading'
import RulesStatusError from './RulesStatusError'
import PrimaryRuleCriteriasList from './PrimaryRuleCriteriasList'
import PrimaryRuleFormCriteria from './PrimaryRuleFormCriteria'
import { RULE_TYPES } from '@/api/business-rules/rules'
import { mapState, mapActions } from 'vuex'
import { cloneDeep, isEqual } from 'lodash-es'
import Vue from 'vue'

export default {
  components: {
    ApLoading,
    RulesStatusError,
    PrimaryRuleCriteriasList,
    PrimaryRuleFormCriteria,
  },
  data() {
    return {
      isCriteriaDialogVisible: false,
      criteriaSelectedIndex: null,
      isLoading: false,
      isError: false,
      selectedFormEntity: null,
      fieldRules: [(v) => !!v || 'Field is required'],
      operatorRules: [(v) => !!v || 'Operator is required'],
      payload: {},
      selectedTabIndex: 0,
    }
  },
  computed: {
    ...mapState('business-rules', {
      multiMapRule: (state) => state.directorRule.rules.MultiMap,
      primaryRule: (state) => state.directorRule.rules.SelectPrimary,
      primaryRuleMetadata: (state) => state.rulesMetadata.SelectPrimary,
      isTriggeringSave: (state) =>
        state.directorRule.rules.SelectPrimary?.isTriggeringSave,
      directorRule: (state) => state.directorRule,
    }),
    selectedTabEntity() {
      return Object.keys(this.payload)[this.selectedTabIndex]
    },
    selectedCriteria() {
      if (this.criteriaSelectedIndex === null) {
        return undefined
      }

      return this.payload[this.selectedTabEntity][this.criteriaSelectedIndex]
    },
  },
  watch: {
    async isTriggeringSave(value) {
      if (value) {
        const invalidTabs = []

        Object.keys(this.payload).forEach((entityName, tabIndex) => {
          if (this.payload[entityName].length === 0) {
            invalidTabs.push(tabIndex)
          }
        })

        if (invalidTabs.length === 0) {
          try {
            if (!isEqual(this.originalPayload, this.payload)) {
              if (this.primaryRule.id) {
                await this.updatePrimaryRule({
                  rule: this.primaryRule,
                  conditions: this.payload,
                })
              } else {
                await this.createPrimaryRule({
                  directorRuleId: this.$route.params.directorRuleId,
                  rule: this.primaryRule,
                  conditions: this.payload,
                })
              }
            }

            this.$emit('saved')
          } finally {
            this.resetTriggerSaveCurrentRule(this.primaryRule.type)
          }
        } else {
          if (!invalidTabs.includes(this.selectedTabIndex)) {
            this.selectedTabIndex = invalidTabs[0]
          }

          this.resetTriggerSaveCurrentRule(this.primaryRule.type)
        }
      }
    },
  },
  created() {
    this.getPrimaryRule()
  },
  methods: {
    ...mapActions('business-rules', [
      'getRule',
      'getRuleEntityMetadata',
      'assignLocalRuleToDirector',
      'resetTriggerSaveCurrentRule',
      'updatePrimaryRule',
      'createPrimaryRule',
    ]),
    async getPrimaryRule() {
      this.isLoading = true
      this.isError = false

      try {
        if (this.directorRule.rules?.SelectPrimary?.id) {
          await this.getRule(this.directorRule.rules.SelectPrimary.id)
        } else {
          const payload = {
            id: null,
            name: `${this.directorRule.name}: Primary Rule`,
            description: 'Manually created Primary rule',
            entities: 'Account',
            type: RULE_TYPES.primary,
          }

          this.assignLocalRuleToDirector({ rule: payload })
        }

        await this.getMetadata()
      } catch {
        this.isError = true
      } finally {
        this.isLoading = false
      }
    },
    async getMetadata() {
      const entitiesToRequest = []

      this.multiMapRule.entities.forEach((entity) => {
        if (!this.primaryRule.criterias) {
          this.payload = {
            ...this.payload,
            [entity]: [],
          }

          this.originalPayload = cloneDeep(this.payload)
        }

        if (!this.primaryRuleMetadata?.[entity]) {
          entitiesToRequest.push(entity)
        }
      })

      if (this.primaryRule.criterias) {
        this.payload = cloneDeep(this.primaryRule.criterias)
        this.originalPayload = cloneDeep(this.payload)
      }

      if (entitiesToRequest.length > 0) {
        await this.getRuleEntityMetadata({
          entities: entitiesToRequest,
          ruleType: RULE_TYPES.primary,
        })
      }
    },
    onAddNew() {
      this.criteriaSelectedIndex = null
      this.isCriteriaDialogVisible = true
    },
    onEdit(criteriaIndex) {
      this.criteriaSelectedIndex = criteriaIndex
      this.isCriteriaDialogVisible = true
    },
    onDelete(index) {
      this.payload[this.selectedTabEntity].splice(index, 1)
    },
    onSave(conditions) {
      if (this.criteriaSelectedIndex === null) {
        this.payload[this.selectedTabEntity] = [
          ...this.payload[this.selectedTabEntity],
          conditions,
        ]
      } else {
        Vue.set(
          this.payload[this.selectedTabEntity],
          this.criteriaSelectedIndex,
          conditions
        )
      }

      this.isCriteriaDialogVisible = false
      this.criteriaSelectedIndex = null
    },
    onCancel() {
      this.isCriteriaDialogVisible = false
      this.criteriaSelectedIndex = null
    },
  },
}
</script>
