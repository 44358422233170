<template>
  <ApDialog
    :value="true"
    title="Primary - Criteria 1"
    max-width="960"
    :actions="actions"
    @new-condition="addNewCondition"
    @cancel="$emit('cancel')"
    @save="onSave"
  >
    <template #content>
      <div class="px-6 py-2 ap-black--text">
        <v-form ref="form">
          <VueDraggable
            :list="conditionsTemporary"
            handle=".handle"
            @end="onDragEnd"
            @start="onDragStart"
          >
            <PrimaryRuleFormCriteriaCondition
              v-for="(condition, index) in conditionsTemporary"
              :key="condition.id"
              :value="condition"
              :entity="entity"
              :is-and-visible="index > 0"
              :is-delete-enabled="conditionsTemporary.length > 1"
              :is-drag-enabled="conditionsTemporary.length > 1"
              class="mb-3"
              @input="updateCondition(index, $event)"
              @delete="deleteCondition(index)"
            />
          </VueDraggable>
        </v-form>
      </div>
    </template>
  </ApDialog>
</template>

<script>
import ApDialog from '@/components/common/ApDialog'
import PrimaryRuleFormCriteriaCondition from './PrimaryRuleFormCriteriaCondition'
import VueDraggable from 'vuedraggable'
import Vue from 'vue'
import { cloneDeep } from 'lodash-es'

const defaultCondition = {
  field: null,
  operator: null,
  value: null,
}

export default {
  components: {
    ApDialog,
    PrimaryRuleFormCriteriaCondition,
    VueDraggable,
  },
  props: {
    conditions: {
      type: Array,
      default: () => [{ ...defaultCondition }],
    },
    entity: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      conditionsTemporary: null,
    }
  },
  computed: {
    actions() {
      return [
        {
          event: 'new-condition',
          label: 'New Condition',
          color: 'primary',
          outlined: true,
          text: true,
          leftIcon: '$mdi-plus',
        },
        {
          event: 'cancel',
          label: 'Cancel',
          color: 'primary',
          outlined: true,
        },
        {
          event: 'save',
          label: 'Done',
          color: 'primary',
        },
      ]
    },
  },
  created() {
    this.conditionsTemporary = cloneDeep(this.conditions).map((el, index) => {
      return { ...el, id: index }
    })
  },
  methods: {
    addNewCondition() {
      this.conditionsTemporary = [
        ...this.conditionsTemporary,
        { id: this.conditionsTemporary.length, ...defaultCondition },
      ]
    },
    updateCondition(conditionIndex, condition) {
      Vue.set(this.conditionsTemporary, conditionIndex, condition)
    },
    deleteCondition(conditionIndex) {
      const conditionsTemp = cloneDeep(this.conditionsTemporary)

      conditionsTemp.splice(conditionIndex, 1)

      this.conditionsTemporary = conditionsTemp
    },
    onDragStart(e) {
      e.target.classList.add('is-grabbing')
    },
    onDragEnd(e) {
      e.target.classList.remove('is-grabbing')
    },
    onSave() {
      if (this.$refs.form.validate()) {
        this.$emit('save', this.conditionsTemporary)
      }
    },
  },
}
</script>

<style>
.handle {
  cursor: grab;
}

.is-grabbing * {
  cursor: grabbing !important;
}
</style>
