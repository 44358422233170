<template>
  <div class="d-flex align-center">
    <v-icon v-if="isDragEnabled" class="handle mr-2"> $mdi-drag </v-icon>

    <v-row dense>
      <v-col cols="4">
        <v-select
          :value="value.field"
          label="Field"
          :rules="fieldRules"
          outlined
          dense
          required
          :items="primaryRuleMetadata[entity]"
          item-value="name"
          item-text="label"
          append-icon="$mdi-chevron-down"
          return-object
          hide-details
          @change="fieldChanged"
        >
          <template #prepend>
            <div
              v-if="isAndVisible"
              class="mt-1 font-weight-medium text-caption"
            >
              AND
            </div>
          </template>
        </v-select>
      </v-col>

      <v-col :cols="operatorDoesNotNeedValue ? 8 : 4">
        <v-select
          :value="value.operator"
          label="Operator"
          :rules="operatorRules"
          outlined
          dense
          required
          :items="selectedField ? selectedField.operators : []"
          :disabled="!selectedField"
          item-value="name"
          item-text="label"
          append-icon="$mdi-chevron-down"
          return-object
          hide-details
          @change="operatorChanged"
        />
      </v-col>

      <v-col v-if="!operatorDoesNotNeedValue" cols="4">
        <v-select
          v-if="selectedField.type === 'boolean'"
          :value="value.value"
          :rules="valueSelectRules"
          outlined
          dense
          required
          hide-details
          :items="[true, false]"
          label="Value"
          :disabled="!selectedOperator"
          @change="valueChanged"
        />

        <v-text-field
          v-else
          :value="value.value"
          :rules="valueRules"
          label="Value"
          outlined
          dense
          required
          hide-details
          :disabled="!selectedOperator"
          @change="valueChanged"
        />
      </v-col>
    </v-row>

    <v-btn v-if="isDeleteEnabled" icon class="ml-2" @click="$emit('delete')">
      <v-icon> $mdi-delete-outline </v-icon>
    </v-btn>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  props: {
    value: {
      type: Object,
      required: true,
    },
    isAndVisible: {
      type: Boolean,
      default: false,
    },
    isDeleteEnabled: {
      type: Boolean,
      default: false,
    },
    isDragEnabled: {
      type: Boolean,
      default: false,
    },
    entity: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      selectedField: {},
      selectedOperator: {},
      fieldRules: [(v) => !!v || 'Field is required'],
      operatorRules: [(v) => !!v || 'Operator is required'],
      valueRules: [(v) => !!v || 'Value is required'],
      valueSelectRules: [(v) => v !== null || 'Value is required'],
    }
  },
  computed: {
    ...mapState('business-rules', {
      primaryRuleMetadata: (state) => state.rulesMetadata.SelectPrimary,
    }),
    operatorDoesNotNeedValue() {
      return ['get_oldest_value', 'get_newest_value'].includes(
        this.selectedOperator?.name
      )
    },
  },
  created() {
    if (this.value.field) {
      this.selectedField = this.primaryRuleMetadata[this.entity].find(
        (field) => field.name === this.value.field
      )

      this.selectedOperator = this.selectedField?.operators.find(
        (operator) => operator.name === this.value.operator
      )
    }
  },
  methods: {
    fieldChanged(value) {
      this.selectedField = value

      this.$emit('input', {
        ...this.value,
        field: value.name,
        operator: null,
        value: null,
      })
    },
    operatorChanged(value) {
      this.selectedOperator = value

      this.$emit('input', {
        ...this.value,
        operator: value.name,
        value: null,
      })
    },
    valueChanged(value) {
      this.$emit('input', {
        ...this.value,
        value,
      })
    },
  },
}
</script>
