<template>
  <v-card outlined>
    <div class="d-flex justify-space-between">
      <div class="d-flex align-center px-2 py-2">
        <v-icon class="handle mr-1"> $mdi-drag </v-icon>
        <span class="font-weight-medium">Criteria {{ index + 1 }}</span>
      </div>

      <v-btn
        text
        small
        outlined
        class="text-none font-weight-regular mt-2 mr-2"
        @click.stop="$emit('delete')"
      >
        <v-icon left> $mdi-delete-outline </v-icon>
        Delete
      </v-btn>
    </div>

    <div class="ap-dark-gray--text px-4 pb-2 text-caption">
      <div v-for="(condition, conditionIndex) in item" :key="conditionIndex">
        <span class="font-weight-bold">
          {{ field(condition.field) && field(condition.field).label }}
        </span>
        {{
          operator(condition.field, condition.operator) &&
          operator(condition.field, condition.operator).label
        }}
        <span class="font-weight-bold">
          {{ condition.value }}
        </span>
      </div>
    </div>
  </v-card>
</template>

<script>
import { mapState } from 'vuex'

export default {
  props: {
    item: {
      type: Array,
      required: true,
    },
    index: {
      type: Number,
      default: 0,
    },
    entity: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapState('business-rules', {
      primaryRuleMetadata: (state) => state.rulesMetadata.SelectPrimary,
    }),
  },
  methods: {
    field(field) {
      return this.primaryRuleMetadata[this.entity]?.find(
        (el) => el.name === field
      )
    },
    operator(field, operator) {
      return this.field(field)?.operators?.find((el) => el.name === operator)
    },
  },
}
</script>
